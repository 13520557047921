import React  from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import { Formik, Field, Form, ErrorMessage } from 'formik'
import axios from "axios"
import { navigate } from '@reach/router';

// action="https://getform.io/f/3df4ad1f-c504-4ad4-87ee-0fd8a4e679b2"
// https://getform.io/f/3df4ad1f-c504-4ad4-87ee-0fd8a4e679b2

export default () => {
  const handleOnSubmit = (values, actions) => {
    console.log('submit', values, actions)

    actions.setSubmitting(true)

    let formData = new FormData()
    formData.set('name', values.name)
    formData.set('email', values.email)
    formData.set('message', values.message)

    axios({
      method: "post",
      url: "https://getform.io/f/3df4ad1f-c504-4ad4-87ee-0fd8a4e679b2",
      data: formData
    })
      .then(response => {
        actions.setSubmitting(false)

        // Clear form
        actions.resetForm({
          values: {
            name: '',
            email: '',
            message: '',
          },
        });

        // Set success message
        navigate('/contact-success');
      })
  };
  return (
    <Layout>
      <Helmet>
        <title>Contact Us | Joe Little Foundation</title>
        <script src="https://www.google.com/recaptcha/api.js?render=6LdNIkMcAAAAAMeClBuqq9ZV7xRCh8Kx4M15AZTf"></script>
      </Helmet>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto flex flex-col">
        <div className="flex">
          <div className="w-1/3">
            <p className="text-xl leading-relaxed pr-12">
              Reach out to us and we'll get back to you as soon as possible.
            </p>
          </div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            validate={values => {
              const errors = {};
              if (!values.name) {
                errors.name = 'Please enter your name';
              }

              if (!values.email) {
                errors.email = 'Please enter your email';
              }

              return errors;
            }}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting }) => (
              <Form method="POST" className="w-2/3 flex flex-col">
                <div className="form-group">
                  <label htmlFor="name-field">Name</label>
                  <Field type="text" id="name-field" name="name" className="form-control" required="required" />
                  <ErrorMessage name="name" component="div" className="text-scarlet-400 text-sm" />
                </div>
                <div className="form-group">
                  <label htmlFor="email-field">Email</label>
                  <Field type="email" id="email-field" name="email" className="form-control" required="required" />
                  <ErrorMessage name="email" component="div" className="text-scarlet-400 text-sm" />
                </div>
                <div className="form-group">
                  <label htmlFor="message-field">Message</label>
                  <Field id="message-field" name="message" className="form-control" component="textarea" rows="5" />
                  <ErrorMessage name="message" component="div" />
                </div>
                <input type="hidden" id="captchaResponse" name="g-recaptcha-response" />
                <button type="submit" disabled={isSubmitting} className="px-6 py-4 text-lg bg-scarlet-500 text-white hover:bg-scarlet-600 rounded transition-colors duration-150 ease-in-out">Send</button>
              </Form>
            )}
          </Formik>
        </div>
      </main>
    </Layout>
  )
}
